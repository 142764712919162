import pluralize from 'pluralize'
import { store } from 'store/index'
import { OrgInviteState } from 'store/modules/orgs'
import { ColourVariant } from '../../design-system/types/colour-variant'

export class InviteBannerVm {
  constructor(private inviteesLength: number, private source: string) {}

  get showBanner() {
    if (!this.invitingOrg) return false

    if (!this.invitingOrg.activeSubscription) {
      return this.invitingOrg.isAtSeatsLimit(this.inviteesLength)
    }
    if (this.inviteesLength > 0) return true
    return false
  }

  get bannerVariant(): ColourVariant {
    switch (this.invitingState) {
      case 'freemium-seats-left':
      case 'active-subscription':
        return 'info'
      case 'freemium-will-use-all-seats':
        return 'warning'
      case 'freemium-no-seats-left':
        return 'danger'
    }
  }

  get invitingState(): OrgInviteState {
    if (!this.invitingOrg) return 'freemium-seats-left'

    return this.invitingOrg.invitingState(this.inviteesLength)
  }

  get invitingOrg() {
    return store.currentUser?.org
  }

  get activeSubscriptionSeatsCopy() {
    if (!this.invitingOrg) return ''

    const seatsLeft = this.invitingOrg?.remainingUsers - this.inviteesLength

    if (seatsLeft > 0) {
      return `This will use up ${this.inviteesLength} of your ${this.invitingOrg?.remainingUsers} seats left.`
    } else if (seatsLeft === 0) {
      return `This will use up all your remaning seats.`
    } else {
      return `This will add ${Math.abs(seatsLeft)} more paid ${pluralize(
        'seat',
        Math.abs(seatsLeft)
      )} to your plan.`
    }
  }

  get archiveUsersLink() {
    return `/orgs/${this.invitingOrg?.slug}/people`
  }

  get upgradeLink() {
    return `/orgs/${this.invitingOrg?.slug}/billing/new`
  }

  get billingInfoLink() {
    return 'https://help.progression.co/changing-seat-numbers'
  }

  get contactUsLink() {
    return 'mailto:support@progression.co'
  }

  get trackingSource(): string {
    return this.source
  }
}
