import { Tag } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import pluralize from 'pluralize'
import React from 'react'
import { SeatsRemainingTagVm } from './seats-remaining-tag-vm'

export type SeatsRemainingTagProps = {
  inviteesLength: number
  className?: string
}

export const SeatsRemainingTag = observer((props: SeatsRemainingTagProps) => {
  const { inviteesLength, className } = props

  const vm = new SeatsRemainingTagVm(inviteesLength)

  if (!vm.showRemainingLabel) return null

  return (
    <Tag className={className} variant={vm.remainingSeatsVariant}>
      {vm.remainingSeats} {pluralize('seat', vm.remainingSeats)} remaining
    </Tag>
  )
})
