import * as React from 'react'
import cn from 'classnames'
import styles from './Toggle.module.scss'
import { ToggleLeftOutline, ToggleRight } from '@easy-eva-icons/react'

export type ToggleProps = {
  checked: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
} & Omit<React.ComponentPropsWithoutRef<'label'>, 'onChange'>

export const Toggle: React.VFC<ToggleProps> = (props) => {
  const { children, checked, onChange, className, ...restProps } = props

  const FilterIcon = checked ? ToggleRight : ToggleLeftOutline

  return (
    <label className={cn(styles.label, className)} {...restProps}>
      {children}
      <input
        checked={checked}
        onChange={onChange}
        type="checkbox"
        readOnly
        className="sr-only"
      />
      <FilterIcon aria-hidden className={styles.icon} />
    </label>
  )
}
