import { store } from 'store/index'
import { Invitee } from './invite-modal'
import { emptyInvitee } from './utils'

export class InviteesListVm {
  INVITEE_LIMIT = 10

  constructor(
    private invitees: Invitee[],
    private setInvitees: (invitees: Invitee[]) => void
  ) {}

  updateInvitee(id: number, newEmail: string) {
    const withUpdated = this.updateByAttribute(id, 'email', newEmail)

    this.setInvitees(withUpdated)
  }

  updatePosition(id: number, positionId: string | null) {
    const withUpdated = this.updateByAttribute(id, 'positionId', positionId)

    this.setInvitees(withUpdated)
  }

  updateAdmin(id: number, isAdmin: boolean) {
    const withUpdated = this.updateByAttribute(id, 'admin', isAdmin)

    this.setInvitees(withUpdated)
  }

  updateByAttribute(
    id: number,
    attribute: string,
    value: string | boolean | null
  ) {
    const withUpdated = this.invitees.map((invitee) => {
      if (id === invitee.id) {
        return {
          ...invitee,
          [attribute]: value,
        }
      }
      return invitee
    })

    return withUpdated
  }

  addInvitee(numberOfInvites: number) {
    const newInvitee = {
      ...emptyInvitee,
      id: numberOfInvites + 1,
    }

    this.setInvitees([...this.invitees, newInvitee])
  }

  removeInvitee(id: number) {
    const withRemoved = this.invitees.filter((i) => i.id !== id)

    this.setInvitees(withRemoved)
  }

  get showRemoveButton() {
    return this.invitees.length > 1
  }

  get atOrgSeatsLimit() {
    return store.currentUser?.org?.isAtSeatsLimit(this.invitees.length)
  }

  get atInviteLimit() {
    return this.invitees.length >= this.INVITEE_LIMIT
  }

  get showAdminCheckbox() {
    return store.currentUser?.orgRole === 'admin'
  }
}
