import { Banner, Link } from 'src/design-system'
import { WithAnalytics } from 'components/with-analytics'
import { observer } from 'mobx-react-lite'
import { Info } from '@phosphor-icons/react'
import pluralize from 'pluralize'
import React from 'react'
import { OrgInviteState } from 'store/modules/orgs'
import { InviteBannerVm } from './invite-banner-vm'

export type InviteBannerProps = {
  inviteesLength: number
  className?: string
  source: string
}

type InviteBannerVariantProps = {
  inviteesLength: number
  vm: InviteBannerVm
}

type InviteVariantTypes = Record<OrgInviteState, React.ReactNode>

export const InviteBanner = observer((props: InviteBannerProps) => {
  const { inviteesLength, className, source } = props

  const vm = new InviteBannerVm(inviteesLength, source)

  const variants: InviteVariantTypes = {
    'freemium-seats-left': (
      <FreemiumSeatsLeft inviteesLength={inviteesLength} vm={vm} />
    ),
    'active-subscription': (
      <ActiveSubscription inviteesLength={inviteesLength} vm={vm} />
    ),
    'freemium-will-use-all-seats': (
      <FreemiumUseAllSeats inviteesLength={inviteesLength} vm={vm} />
    ),
    'freemium-no-seats-left': (
      <FreemiumNoSeatsLeft inviteesLength={inviteesLength} vm={vm} />
    ),
  }

  if (!vm.showBanner) return null

  return (
    <Banner variant={vm.bannerVariant} className={className}>
      <div className="flex flex-row items-center gap-x-2 w-full">
        <Info size={18} className="mr-1 w-8" />
        {variants[vm.invitingState]}
      </div>
    </Banner>
  )
})

const FreemiumNoSeatsLeft = (props: InviteBannerVariantProps) => {
  return (
    <p className="text-red-600">
      You’ve reached your seat limit. To get more seats{' '}
      <WithAnalytics
        event="$track_invite_banner_clicked"
        params={{
          source: props.vm.trackingSource,
          action: 'start-trial',
          sourceDetail: 'freemium-no-seats-left',
        }}
      >
        <Link
          isExternal
          className="p-0 text-red-600"
          href={props.vm.upgradeLink}
        >
          start your free trial
        </Link>
      </WithAnalytics>{' '}
      or{' '}
      <WithAnalytics
        event="$track_invite_banner_clicked"
        params={{
          source: props.vm.trackingSource,
          action: 'contact-us',
          sourceDetail: 'freemium-no-seats-left',
        }}
      >
        <Link
          isExternal
          className="p-0 text-red-600"
          href={props.vm.contactUsLink}
        >
          contact us
        </Link>
      </WithAnalytics>
    </p>
  )
}

const FreemiumUseAllSeats = (props: InviteBannerVariantProps) => {
  return (
    <p className="text-yellow-800">
      This will use all of your remaining seats on your plan. To get more seats{' '}
      <WithAnalytics
        event="$track_invite_banner_clicked"
        params={{
          source: props.vm.trackingSource,
          action: 'archive-users',
          sourceDetail: 'freemium-will-use-all-seats',
        }}
      >
        <Link
          href={props.vm.archiveUsersLink}
          className="p-0 text-yellow-800"
          data-turbo-frame="content"
          data-turbo-action="advance"
        >
          archive some users,
        </Link>
      </WithAnalytics>{' '}
      <WithAnalytics
        event="$track_invite_banner_clicked"
        params={{
          source: props.vm.trackingSource,
          action: 'start-trial',
          sourceDetail: 'freemium-will-use-all-seats',
        }}
      >
        <Link
          isExternal
          className="p-0 text-yellow-800"
          href={props.vm.upgradeLink}
        >
          start a free trial
        </Link>
      </WithAnalytics>{' '}
      or{' '}
      <WithAnalytics
        event="$track_invite_banner_clicked"
        params={{
          source: props.vm.trackingSource,
          action: 'contact-us',
          sourceDetail: 'freemium-will-use-all-seats',
        }}
      >
        <Link
          isExternal
          className="p-0 text-yellow-800"
          href={props.vm.contactUsLink}
        >
          contact us
        </Link>
      </WithAnalytics>
    </p>
  )
}

const ActiveSubscription = (props: InviteBannerVariantProps) => {
  return (
    <p className="text-blue-700">
      {props.vm.activeSubscriptionSeatsCopy}{' '}
      <WithAnalytics
        event="$track_invite_banner_clicked"
        params={{
          source: props.vm.trackingSource,
          action: 'read-more',
          sourceDetail: 'active-subscription',
        }}
      >
        <Link
          isExternal
          className="p-0 text-blue-700"
          href={props.vm.billingInfoLink}
        >
          Read More
        </Link>
      </WithAnalytics>
    </p>
  )
}

const FreemiumSeatsLeft = (props: InviteBannerVariantProps) => {
  return (
    <p className="text-blue-700">
      This will add {props.inviteesLength} more{' '}
      {pluralize('seat', props.inviteesLength)} to your plan. To get more seats{' '}
      <WithAnalytics
        event="$track_invite_banner_clicked"
        params={{
          source: props.vm.trackingSource,
          action: 'start-trial',
          sourceDetail: 'freemium-seats-left',
        }}
      >
        <Link
          isExternal
          className="p-0 text-blue-700"
          href={props.vm.upgradeLink}
        >
          start your free trial
        </Link>
      </WithAnalytics>{' '}
      or{' '}
      <WithAnalytics
        event="$track_invite_banner_clicked"
        params={{
          source: props.vm.trackingSource,
          action: 'contact-us',
          sourceDetail: 'freemium-seats-left',
        }}
      >
        <Link
          isExternal
          className="p-0 text-blue-700"
          href={props.vm.contactUsLink}
        >
          contact us
        </Link>
      </WithAnalytics>
    </p>
  )
}
