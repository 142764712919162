import { Button, Input, InputGroup, Toggle } from 'src/design-system'
import { PositionSelect } from 'components/position-select'
import { observer } from 'mobx-react-lite'
import { MinusCircle } from '@phosphor-icons/react'
import * as React from 'react'
import { useStore } from 'store/context'
import { Invitee } from './invite-modal'
import { InviteesListVm } from './invitees-list-vm'
import cn from 'classnames'

type InviteesListProps = {
  invitees: Invitee[]
  setInvitees: (list: Invitee[]) => void
}

export const InviteesList = observer((props: InviteesListProps) => {
  const { invitees, setInvitees } = props

  const { positions } = useStore()

  const [numberOfInvites, setNumberOfInvites] = React.useState(1)

  const vm = new InviteesListVm(invitees, setInvitees)

  return (
    <>
      <div className="grid grid-cols-9 gap-1 text-gray-900 font-bold text-base mb-5">
        <div className="col-span-2">
          Email <span className="text-gray-600">*</span>
        </div>
        <div className="col-span-2">First name</div>
        <div className="col-span-2">Last name</div>

        <div className={cn(vm.showAdminCheckbox ? 'col-span-2' : 'col-span-3')}>
          Position
        </div>
        {vm.showAdminCheckbox && (
          <div className="col-span-1d sm:col-span-1 text-center">Admin</div>
        )}
      </div>

      <div className="mb-5 grid gap-y-3">
        {invitees.map((invitee) => {
          return (
            <div
              className="grid grid-cols-9 gap-x-1 gap-y-1 sm:gap-y-0 relative"
              key={invitee.id}
            >
              {vm.showRemoveButton && (
                <MinusCircle
                  className="absolute top-4 -left-6 cursor-pointer"
                  onClick={() => vm.removeInvitee(invitee.id)}
                />
              )}
              <div className="col-span-9 sm:col-span-2">
                <InputGroup.Root>
                  <Input
                    name="emails[]"
                    aria-label="Email"
                    autoComplete="off"
                    placeholder="name@example.com"
                    className=""
                    onChange={(e) =>
                      vm.updateInvitee(invitee.id, e.target.value)
                    }
                    isInvalid={!!invitee.error}
                  />
                  <InputGroup.ErrorMessage>
                    {invitee.error}
                  </InputGroup.ErrorMessage>
                </InputGroup.Root>
              </div>

              <div className="col-span-4 sm:col-span-2">
                <InputGroup.Root>
                  <Input
                    name="fnames[]"
                    aria-label="First name"
                    autoComplete="off"
                    placeholder="First name"
                    className=""
                    onChange={(e) =>
                      setInvitees(
                        vm.updateByAttribute(
                          invitee.id,
                          'fname',
                          e.target.value
                        )
                      )
                    }
                  />
                </InputGroup.Root>
              </div>

              <div className="col-span-5 sm:col-span-2">
                <InputGroup.Root>
                  <Input
                    name="lnames[]"
                    aria-label="Last name"
                    autoComplete="off"
                    placeholder="Last name"
                    className=""
                    onChange={(e) =>
                      setInvitees(
                        vm.updateByAttribute(
                          invitee.id,
                          'lname',
                          e.target.value
                        )
                      )
                    }
                  />
                </InputGroup.Root>
              </div>

              <div
                className={cn(
                  vm.showAdminCheckbox
                    ? 'col-span-7 sm:col-span-2'
                    : 'col-span-7 sm:col-span-3'
                )}
              >
                <PositionSelect
                  className="w-full"
                  positions={positions.forOrg}
                  prefix={''}
                  borderless={false}
                  placeholder="I'll choose later"
                  required={false}
                  disablePositionsWithNoRequirements={false}
                  aria-label="Position"
                  onChange={(positionId) =>
                    vm.updatePosition(invitee.id, positionId)
                  }
                  value={null}
                  isClearable={true}
                />
              </div>
              {vm.showAdminCheckbox && (
                <div className="col-span-2 sm:col-span-1 flex items-start justify-center mt-1">
                  <Toggle
                    checked={invitee.admin}
                    onClick={(e) => {
                      e.preventDefault()
                      vm.updateAdmin(invitee.id, !invitee.admin)
                    }}
                  />
                </div>
              )}
            </div>
          )
        })}
      </div>

      {!vm.atOrgSeatsLimit && (
        <>
          {vm.atInviteLimit ? (
            <p className="text-gray-600 mb-7">
              Sorry, you can only invite {vm.INVITEE_LIMIT} teammates at once.
            </p>
          ) : (
            <Button
              variant="outline"
              colourVariant="paper"
              type="button"
              onClick={() => {
                vm.addInvitee(numberOfInvites)
                setNumberOfInvites(numberOfInvites + 1)
              }}
              className="border-0 bg-transparent mb-7 pl-0"
            >
              <span className="text-theme-70">+</span> Add another teammate
            </Button>
          )}
        </>
      )}
    </>
  )
})
