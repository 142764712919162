import { store } from 'store/index'
import { ColourVariant } from '../../design-system/types/colour-variant'

export class SeatsRemainingTagVm {
  constructor(private inviteesLength: number) {}

  get showRemainingLabel() {
    return !this.invitingOrg?.activeSubscription
  }

  get remainingSeatsVariant(): ColourVariant {
    return this.invitingOrg?.isAtSeatsLimit(this.inviteesLength)
      ? 'danger'
      : 'success'
  }

  get remainingSeats(): number {
    if (this.invitingOrg) {
      return Math.max(0, this.invitingOrg?.remainingUsers - this.inviteesLength)
    }
    return 0
  }

  get invitingOrg() {
    return store.currentUser?.org
  }
}
